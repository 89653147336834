import React, {useEffect, useState} from "react";
import { Row, Col } from "antd";
import useAxios from "axios-hooks";
import { Card, Tooltip, Icon, Button } from "rt-design-system-backup";
import FileDownload from "js-file-download";
import axios from "axios";
import {DateRangeInput} from "rt-design-system-backup";

import { dateFormat } from "../../Maturities";
import Page from "../../../Page";
import { InputGroup } from "../../../Form";
import DoughnutChart from "./DoughnutChart";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import { url, getCustomShortcuts, ytd } from "../../../../helpers";
import LoadingIcon from '../../../Icons/loading';
import CustomerTable from "./CustomerTable";

const CustomerDashboard = () => {
    const [isExtraLargeScreen, setIsExtraLargeScreen] = useState(window.innerWidth > 1600)
    const [customer, setCustomer] = useState()
    const [customers, setCustomers] = useState([])
    const [startDate, setStartDate] = useState(ytd);
    const [endDate, setEndDate] = useState(new Date());
    const [monthType, setMonthType] = useState('global');
    const [{ loading: customersLoading, data: customersData = [] }] = useAxios({
        url: url("/customer/"),
    });
    
    useEffect(() => {
        window.addEventListener("resize", () => setIsExtraLargeScreen(window.innerWidth > 1700));
    }, [])

    const handleDateChange = (data) => {
        const start = data[0] || new Date()
        const end = data[1] || new Date()
        setStartDate(start)
        setEndDate(end)
    }

    const downloadCsv = (e) => {
        e.preventDefault();
        axios({
          url: url(`analyticscsv/download/?start_date=${dateFormat.formatDate(startDate)}&end_date=${dateFormat.formatDate(endDate)}`),
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          FileDownload(response.data,`analytics_PnL_report_${dateFormat.formatDate(startDate)}_${dateFormat.formatDate(endDate)}.csv`);
        });
    };

    useEffect(()=> {
        if (customersData.length) {
            setCustomer(-1)
            setCustomers([{display_name: 'All Customers', id: -1}, ...customersData])
        }
    }, [customersData])

    return (
        <>
        {customersLoading === true || customers.length === 0 ? (
            <div style={{ alignItems: "center", justifyContent: "center", height: '100%', display: 'flex'}}>
                <LoadingIcon />
                <p>Loading Data</p>
            </div>
        ) : (
            <Page title={"All Customers"} tabs={[]} leftActions={[]} actionsMarginBottom={'5px'}
                rightActions={[
                    <span>
                        <InputGroup
                            required
                            name="not sure"
                            type="select"
                            formStyle={{margin: 0, marginRight: 20, }}
                            items={customers.map((item) => {
                            return {
                                name: item.display_name,
                                value: item.id,
                            };
                            })}
                            onChange={(e) => {setCustomer(e[0].value)}}
                            placeholder={'Select Customer Type'}
                            defaultValue={customer ? [customer] : [customers[0].id]}
                        />
                    </span>,
                    <span>
                        <InputGroup
                            required
                            name="month_type"
                            type="select"
                            formStyle={{margin: 0, marginRight: 20, }}
                            items={['Global', 'Future', 'Spot'].map((item) => {
                            return {
                                name: item,
                                value: item.toLowerCase(),
                            };
                            })}
                            onChange={(e) => {setMonthType(e[0].value)}}
                            placeholder={'Select Pnl Type'}
                            defaultValue={['global']}
                        />
                    </span>,
                    <span>
                        <DateRangeInput
                            singleMonthOnly
                            allowSingleDayRange
                            {...dateFormat}
                            popoverProps={{ minimal: true }}
                            value={[startDate, endDate]}
                            onChange={handleDateChange}
                            shortcuts={getCustomShortcuts(true)}
                        />
                    </span>
                ]}
            >
             <div>
                <Row justify="space-between">
                    <Col xs={24} sm={24} md={6} lg={6} xl={isExtraLargeScreen ? 6 : 7} style={{paddingRight: 16}}>
                        <Card elevation={1}>
                            <DoughnutChart chartText={"12256 total autoquote"} start_date={startDate} end_date={endDate} customer_id={customer} pnlType={monthType} />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{paddingRight: 16}}>
                        <Card elevation={1} style={{height: '100%'}}>
                            <PieChart start_date={startDate} end_date={endDate} customer_id={customer} />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={isExtraLargeScreen ? 12 : 11} style={{paddingRight: 16}}>
                        <BarChart start_date={startDate} end_date={endDate} customer_id={customer} monthType={monthType}/>
                    </Col>
                </Row>
                <Row justify="end" style={{marginTop: '20px', marginRight: '20px'}} onClick={downloadCsv} >
                <Tooltip intent="primary" content="Download Table">
                    <div
                        style={{
                        cursor: "pointer",
                        }}
                    >
                        <Icon size={20} icon="download"/>
                        &nbsp;&nbsp;<u>Download</u>
                    </div>
                </Tooltip>
                </Row>
                <Row justify="space-between" style={{marginTop: '20px'}}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{paddingRight: 16}}>
                        <CustomerTable start_date={startDate} end_date={endDate} customer_id={customer} customers={customers} monthType={monthType}/>
                    </Col>
                </Row>
             </div>
            </Page> 
        )}
        </>
    )
}
export default CustomerDashboard;
