import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { Button, Icon, Spinner } from "rt-design-system-backup";
import { Table } from "antd";
import * as XLSX from "xlsx";
import axios from "axios";
import {
  errorToaster,
  formatDate,
  getExcelRow,
  successToaster,
  url,
} from "../../helpers";
import "./style.scss";

// Import Ant Design and custom icons
import { SearchOutlined } from "@ant-design/icons";
import { FilterDropDown } from "./FilterDropDown";
import ReconcileIcon from "../Icons/reconcile";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useQueryParams } from "../../hooks/useQueryParams";

const handleResponse = (response, successMessage, errorMessage) =>
  response
    .then(() => successToaster(successMessage))
    .catch(() => errorToaster(errorMessage));

const request = (id, action, successMessage, errorMessage) =>
  handleResponse(
    axios({
      url: url(`/trade_sync/${id}/${action}/`),
      method: "PUT",
    }),
    successMessage,
    errorMessage,
  );

const sendRequest =
  (reload) => (id) => (action, successMessage, errorMessage) => async (e) => {
    e.preventDefault();
    await request(id, action, successMessage, errorMessage);
    reload(id);
  };

const actionCallbacks = ({ id }, actionCallback) => {
  const actionCallbackForRecord = actionCallback(id);
  return {
    resendCallback: actionCallbackForRecord(
      "resend",
      "Trade queued for resending",
      "Failed to resend trade",
    ),
    regenerateAndResendCallback: actionCallbackForRecord(
      "regenerate_and_resend",
      "Trade payload regenerated and queued for resending",
      "Failed to resend trade",
    ),
    manualSentCallback: actionCallbackForRecord(
      "mark_as_sent",
      "Marked Sent Successfully",
      "Error Sending",
    ),
  };
};
/**
 * RenderItem function for pagination.
 * Renders the previous and next buttons in the table pagination.
 * @param _
 * @param {string} type - The type of pagination element.
 * @param {JSX.Element} originalElement - The default pagination element.
 * @returns {JSX.Element} Custom pagination element.
 */
export const renderItem = (_, type, originalElement) => {
  switch (type) {
    case "prev":
      return <Button icon="arrow-left" intent="primary" />;
    case "next":
      return <Button icon="arrow-right" intent="primary" />;
    default:
      return originalElement;
  }
};

const EnabledIcon = ({ icon, linkTo = null, onClick = () => null, title }) => (
  <span title={title}>
    <Link to={linkTo ?? "#"}>
      <Icon icon={icon} style={{ cursor: "pointer" }} onClick={onClick} />
    </Link>
  </span>
);

const EnabledIcons = ({ record, actionCallback }) => {
  const { resendCallback, regenerateAndResendCallback, manualSentCallback } =
    actionCallbacks(record, actionCallback);
  const resend = useCallback((e) => resendCallback(e), [resendCallback]);
  const regenerateAndResend = useCallback(
    (e) => regenerateAndResendCallback(e),
    [regenerateAndResendCallback],
  );
  const manualSent = useCallback(
    (e) => manualSentCallback(e),
    [manualSentCallback],
  );
  return (
    <>
      {/* <span title="edit">
        <EnabledIcon
          icon="edit"
          linkTo={`/stp-api-console/update/${record.id}`}
        />
      </span>
      <EnabledIcon title={"Regenerate & Resend"} icon="repeat" onClick={resend} />
      <span title="Manual Sent">
        <Link onClick={manualSent}>
          <ReconcileIcon />
        </Link>
      </span> */}
    </>
  );
};

const isEditable = ({ status }) =>
  ["Waiting", "Successful", "Manual sent"].includes(status);

const isEnabled = ({ regenerate_and_resend_enabled }) =>
  !regenerate_and_resend_enabled;

const ActionIcons = ({ record, actionCallback }) =>
  isEditable(record) || isEnabled(record) ? (
    ""
  ) : (
    <EnabledIcons record={record} actionCallback={actionCallback} />
  );

/**
 * TableInterAction Component.
 * Renders the STP Mapping table with functionalities like filtering and searching.
 * @param {Object[]} data - The data to display in the table.
 * @param {boolean} loading - Loading state flag.
 * @param {function} reload - Callback to trigger refreshing the table.
 * @returns {JSX.Element} The STP Mapping table component.
 */
const TableInterAction = ({
  data,
  loading,
  reload,
  setSelectedRow,
  selectedRow,
}) => {
  const [searchText, setSearchText] = useState("");

  const history = useHistory();
  const queryParams = useQueryParams();

  const actionCallback = sendRequest(reload);

  const uniqueStatus = Array.from(
    new Set(data.map((log) => log.status)),
  ).sort();
  const uniqueAcknowledgementMessages = Array.from(
    new Set(data.map((log) => log.acknowledgementMessage)),
  ).filter(Boolean);
  const acknowledgementMessageFilters = uniqueAcknowledgementMessages.map(
    (message) => ({ text: message, value: message }),
  );

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const downloadXlsx = (data) => {
    const parsedData = JSON.parse(data.payload)[0];

    if (!parsedData || !parsedData?.Trade_Status || !parsedData.Price)
      return {};

    const excelRow = getExcelRow(
      parsedData,
      data.fusionID,
      data.id,
      data.DDPId,
    );

    // Create the first sheet with trade data
    const ws = XLSX.utils.json_to_sheet([excelRow]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Check if Is_Standard_Formula is true and create another sheet
    if (parsedData.Is_Standard_Formula === "True") {
      const formulaVariables = [];

      // Dynamically find all Legs and their related data
      let legNumber = 1;
      while (parsedData[`Leg${legNumber}_From_Date`]) {
        formulaVariables.push({
          "Trade Sr No": data.DDPId,
          "Standard Formula": parsedData.Standard_Formula_Code,
          Variable: `Month Avg ${legNumber}`,
          "Start Date": parsedData[`Leg${legNumber}_From_Date`],
          "End Date": parsedData[`Leg${legNumber}_To_Date`],
        });
        legNumber++;
      }

      // Create the second sheet with standard formula variables
      if (formulaVariables.length > 0) {
        const wsFormula = XLSX.utils.json_to_sheet(formulaVariables);
        XLSX.utils.book_append_sheet(wb, wsFormula, "StandardFormulaVariables");
      }
    }

    // Write the workbook to a file
    XLSX.writeFile(wb, "tradesync.xlsx");
  };

  // {selectedKeys, setSelectedKeys, handleReset, handleSearch, clearFilters, confirm}
  const columns = [
    {
      title: "STP ID",
      dataIndex: "id",
      // fixed: "left",
      sorter: (a, b) => a.id - b.id,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterDropDown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleReset={handleReset}
          handleSearch={handleSearch}
          clearFilters={clearFilters}
          confirm={confirm}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.id.toString().includes(value.toString()),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : "-"}
        />
      ),
    },
    {
      title: "Request ID",
      dataIndex: "requestId",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterDropDown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleReset={handleReset}
          handleSearch={handleSearch}
          clearFilters={clearFilters}
          confirm={confirm}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.requestId.toString().includes(value.toString()),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : "-"}
        />
      ),
    },
    {
      title: "Fusion ID",
      dataIndex: "fusionID",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterDropDown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleReset={handleReset}
          handleSearch={handleSearch}
          clearFilters={clearFilters}
          confirm={confirm}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) => {
        const fusionID = record.fusionID !== null && record.fusionID !== undefined ? record.fusionID.toString() : '';
        const searchValue = value !== null && value !== undefined ? value.toString() : '';
        return fusionID.includes(searchValue);
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : "-"}
        />
      ),
    },
    {
      title: "Acknowledgement Message",
      dataIndex: "acknowledgementMessage",
      render: (text) => (text ? text.toString() : "-"),
      filterSearch: true,
      filters: acknowledgementMessageFilters,
      onFilter: (value, record) =>
        record.acknowledgementMessage &&
        record.acknowledgementMessage.includes(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      filterSearch: true,
      filters: uniqueStatus.map((name) => ({ text: name, value: name })),
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      filterSearch: true,
      sorter: (a, b) => {
        const fromDate = new Date(b.createdAt);
        const toDate = new Date(a.createdAt);
        return fromDate.getTime() - toDate.getTime();
      },
      render: (dateString) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {dateString ? formatDate(dateString) : "-"}
          </div>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
  ];
  columns.push({
    title: "Actions",
    dataIndex: "",
    key: "x",
    render: (_, record) => {
      return (
        <div style={{ whiteSpace: "nowrap" }}>
          <span title="View">
            <EnabledIcon
              linkTo={`/stp-api-console/${record.id}`}
              icon="eye-open"
            />
          </span>

          <ActionIcons record={record} actionCallback={actionCallback} />

          <span title="Download">
            <Link>
              <EnabledIcon
                icon="download"
                onClick={() => {
                  const d = data.find((r) => r.id === record.id);

                  downloadXlsx({ ...record, payload: d.payload });
                }}
              />
            </Link>
          </span>
        </div>
      );
    },
  });

  //Row selection
  const rowSelection = {
    type: "checkbox",
    selectedRowKeys: selectedRow,
    onChange: (row) => {
      setSelectedRow(row);
    },
  };

  // Renders the STP Mapping table
  return (
    <div className="rtcmd-stp-api-console-table">
      <Table
        locale={{
          emptyText: "No STP API Objects to display",
        }}
        loading={
          loading
            ? {
                delay: 1500,
                indicator: <Spinner size={32} />,
              }
            : false
        }
        scroll={{
          x: data && data.length > 0,
        }}
        rowSelection={rowSelection}
        size="small"
        expandIconColumnIndex={13}
        columns={columns}
        dataSource={data}
        tableLayout="auto"
        onChange={(pagination) => {
          history.push({
            pathname: window.location.pathname,
            search: `?page=${pagination.current}`,
          });
        }}
        pagination={{
          current: Number(queryParams.get("page")) || 1,
          pageSize: 20,
          itemRender: renderItem,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

/**
 * STPAPIConsoleTable Component.
 * Wrapper component for TableInterAction to handle the data prop.
 * @param {Object[]} data - The data to be passed to the TableInterAction component.
 * @param {boolean} loading - Loading state flag.
 * @param {function} reload - Callback to trigger refreshing the table.
 * @returns {JSX.Element} The STPAPIConsoleTable component.
 */
const STPAPIConsoleTable = ({
  data,
  loading,
  reload,
  setSelectedRow,
  selectedRow,
}) => {
  return (
    <TableInterAction
      data={data.sort((a, b) => b.id - a.id)}
      loading={loading}
      reload={reload}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
    />
  );
};

export default STPAPIConsoleTable;
